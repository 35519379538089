import { Button } from '@/components/common';
import styles from './HighDemand.module.css';

interface HighDemandProps {
  onRefresh: () => void;
  onReturn: () => void;
  isLoadingRefresh?: boolean;
}
export const HighDemand = ({ onRefresh, onReturn, isLoadingRefresh }: HighDemandProps) => {
  return (
    <div className={styles.container}>
      <h3 className={styles.title}>Try again later</h3>
      <p className={styles.description}>
        We are currently experiencing high demand in transactions. Please hang tight and check back with us shortly.
      </p>
      <Button fluid onPress={() => onRefresh()} loading={isLoadingRefresh}>
        Refresh
      </Button>
      <Button variants="subtle" fluid onPress={() => onReturn()}>
        Return
      </Button>
    </div>
  );
};
