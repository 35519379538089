import Lottie from 'react-lottie';
import processing from '../../../../public/animations/payment-processing.json';
import styles from './VerificationPending.module.css';

interface VerificationPendingProps {
  description?: string;
}

export const VerificationPending = ({
  description,
}: VerificationPendingProps) => {
  return (
    <div className={styles.container}>
      <Lottie
        isClickToPauseDisabled
        options={{
          loop: true,
          autoplay: true,
          animationData: processing,
        }}
        width={64}
        height={64}
        isPaused={false}
        ariaRole=""
      />
      <h3 className={styles.title}>Verification Pending</h3>
      <p className={styles.description}>{description}</p>
    </div>
  );
};
