import React from 'react';
import { Location } from '../../form';
import styles from './UpdateLocation.module.css';

interface UpdateLocationProps {
  onSuccess: () => void;
}
export const UpdateLocation = ({ onSuccess }: UpdateLocationProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>Please enter your residential address</div>
      <Location isPopup onSuccess={onSuccess} buttonText="Submit" />
    </div>
  );
};
