import { Emoji } from '@/utils/functions';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { FC } from 'react';
import { useAcceptTerms } from '@/api';
import { showNotification } from '@/utils/showNotification';
import { Button } from '../../buttons';
import styles from './TermsAndPrivacy.module.css';
import { Checkbox } from '../../form';

interface TermsAndPrivacyFormValue {
  agree: string | undefined;
}

interface TermsAndPrivacyProps {
  onClose: () => void;
}

export const TermsAndPrivacy: FC<TermsAndPrivacyProps> = ({ onClose }) => {
  const { isDesktop } = useMediaQuery();
  const { handleSubmit, control, watch } = useForm<Partial<TermsAndPrivacyFormValue>>({
    defaultValues: {
      agree: '',
    },
    mode: 'onTouched',
  });
  const { mutateAsync: AcceptTerms } = useAcceptTerms();

  const watchedAgree = watch().agree;

  const onSubmit: SubmitHandler<Partial<TermsAndPrivacyFormValue>> = async (data) => {
    try {
      await AcceptTerms();
    } catch (e) {
      showNotification();
    }
    return onClose();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.container}>
      <h3 className={styles.title}>
        We are excited to introduce RelayPay 2.0 <Emoji symbol="🚀" />
      </h3>
      <p className={styles.description}>
        We&apos;ve undergone a major experience uplift and updated our terms and conditions to enhance your experience
        and ensure your safety. Please take a moment to review the new terms & conditions.
      </p>

      <Controller
        name="agree"
        control={control}
        render={({ field: { ref, ...rest } }) => (
          <Checkbox {...rest} className={styles.checkbox} size={isDesktop ? 'large' : 'medium'}>
            <div className={styles.legal}>
              I Agree to RelayPay{' '}
              <a
                href="https://relaypay.io/terms-and-conditions"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.link}
              >
                Terms of Use
              </a>{' '}
              and{' '}
              <a
                href="https://relaypay.io/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.link}
              >
                Privacy Policy
              </a>
            </div>
          </Checkbox>
        )}
      />

      <Button fluid isDisabled={!watchedAgree} className={styles.button} type="submit">
        Agree
      </Button>
    </form>
  );
};
