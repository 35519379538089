import { zodResolver } from '@hookform/resolvers/zod';
import clsx from 'clsx';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useWindowSize } from 'usehooks-ts';
import { scamRiskWarningSchema } from '@/utils/validation';
import { Button } from '../../buttons';
import { CheckboxGroup, CheckboxGroupItem, Input } from '../../form';
import styles from './ScamWarning.module.css';

interface ScamWarningProps {
  onClickNext: () => void;
}

interface FormData {
  confirm: string;
  checkboxes: string[];
}
const values = [
  'I have NOT created or shared my account with any third party.',
  'I understand that ALL transactions are NOT reversible and I confirm the wallet address I have keyed in is accurate.',
  'I understand if I send funds to a wallet address over which I do not have complete control, all of my funds may be lost.',
  'I understand scammers are targeting users with investment scams.',
  'I understand RelayPay is NOT affiliated with any third party.',
];

export const ScamWarning = ({ onClickNext }: ScamWarningProps) => {
  const { width } = useWindowSize();

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<FormData>({
    resolver: zodResolver(scamRiskWarningSchema),
    defaultValues: {
      confirm: '',
      checkboxes: [],
    },
    mode: 'onTouched',
  });

  const onSubmit: SubmitHandler<FormData> = (data) => {};

  return (
    <form className={styles.container} onSubmit={handleSubmit(onSubmit)}>
      <h3 className={styles.title}>Scam Risk Warning!</h3>
      <div className={styles.warningInnerWrapper}>
        <p className={styles.description}>Please be aware of investment related scams.</p>
        <div className={styles.warningInner}>
          <div className={styles.info}>
            <h4 className={styles.subtitle}>Warning signs of an investment scam:</h4>
            <p className={styles.subdescription}>
              You are contacted unexpectedly: You receive a call, email, text or message on social media from someone
              offering unsolicited investment advice.
            </p>
            <h4 className={clsx(styles.subtitle, styles.subtitleWithMargin)}>
              Type keyword to continue with your transaction
            </h4>
            <Controller
              name="confirm"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  label="Enter Keyword"
                  placeholder="Type CONFIRM to continue"
                  inputFieldClassName={styles.input}
                  size={width > 1000 ? 'large' : 'medium'}
                  inputClassName={styles.input}
                  labelClassName={styles.inputLabel}
                />
              )}
            />
          </div>
          <div className={styles.info}>
            <h4 className={styles.subtitle}>Please confirm you understand the following:</h4>
            <Controller
              name="checkboxes"
              control={control}
              render={({ field }) => (
                <CheckboxGroup className={styles.checkboxGroup} {...field}>
                  {values.map((value) => {
                    return (
                      <CheckboxGroupItem
                        value={value}
                        key={value}
                        className={styles.checkbox}
                        size={width > 1000 ? 'large' : 'medium'}
                      >
                        {value}
                      </CheckboxGroupItem>
                    );
                  })}
                </CheckboxGroup>
              )}
            />
          </div>
        </div>
      </div>
      <Button className={styles.button} type="submit" onPress={() => onClickNext()} isDisabled={!isValid}>
        Continue
      </Button>
    </form>
  );
};
