import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useWindowSize } from 'usehooks-ts';
import { createPasswordSchema } from '@/utils/validation';
import { Button } from '../../../../buttons';
import { Input } from '../../../../form';
import styles from './EnterNewData.module.css';

interface FormData {
  password: string;
  confirmPassword: string;
}

interface EnterNewPasswordProps {
  onSubmit: (data: FormData) => void;
  onClose: () => void;
  isLoading?: boolean;
}

export const EnterNewPassword = ({ onSubmit, onClose, isLoading }: EnterNewPasswordProps) => {
  const { width } = useWindowSize();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordConfirmVisible, setPasswordConfirmVisible] = useState(false);
  const {
    handleSubmit,
    control,
    trigger,
    formState: { isDirty, isValid },
  } = useForm<FormData>({
    resolver: zodResolver(createPasswordSchema),
    defaultValues: { password: '', confirmPassword: '' },
    mode: 'all',
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.inputWrapper}>
        <Controller
          name="password"
          control={control}
          render={({ field: { ref, ...rest }, fieldState }) => (
            <Input
              {...rest}
              onChange={(e) => {
                rest.onChange(e);
                trigger('confirmPassword');
              }}
              className={styles.inputTop}
              label="Enter Password"
              type={passwordVisible ? 'text' : 'password'}
              size={width > 1000 ? 'large' : 'medium'}
              placeholder="Enter password"
              buttonIcon={passwordVisible ? 'hide' : 'show'}
              onButtonPress={() => setPasswordVisible(!passwordVisible)}
              iconButtonClassName={!passwordVisible && styles.inputIcon}
              errorMessage={fieldState.error?.message}
              isMessageAbsolute={(fieldState.error?.message?.length ?? 0) < 50}
            />
          )}
        />
        <Controller
          name="confirmPassword"
          control={control}
          render={({ field, fieldState }) => (
            <Input
              {...field}
              className={styles.inputBottom}
              label="Re-Enter Password"
              type={passwordConfirmVisible ? 'text' : 'password'}
              size={width > 1000 ? 'large' : 'medium'}
              placeholder="Re-Enter password"
              buttonIcon={passwordConfirmVisible ? 'hide' : 'show'}
              onButtonPress={() => setPasswordConfirmVisible(!passwordConfirmVisible)}
              iconButtonClassName={!passwordConfirmVisible && styles.inputIcon}
              errorMessage={fieldState.error?.message}
            />
          )}
        />
      </div>
      <div className={styles.buttons}>
        <Button className={styles.button} isDisabled={!isDirty || !isValid} type="submit" loading={isLoading}>
          Update
        </Button>
        <Button className={styles.button} variants="subtle" size="mediumSubtle" onPress={onClose}>
          Cancel
        </Button>
      </div>
    </form>
  );
};
