import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useWindowSize } from 'usehooks-ts';
import { phoneNumberSchema } from '@/utils/validation';
import { Button } from '../../../../buttons';
import { SelectRCPhoneCountry } from '../../../../form';
import styles from './EnterNewData.module.css';

interface FormData {
  phoneNumber: string;
}

interface EnterNewPhoneProps {
  onClose: () => void;
  onSubmit: (phoneNumber: string) => void;
  isLoading?: boolean;
}

export const EnterNewPhone = ({ onClose, onSubmit, isLoading }: EnterNewPhoneProps) => {
  const { width } = useWindowSize();
  const {
    handleSubmit,
    control,
    formState: { isDirty, isValid },
  } = useForm<FormData>({
    resolver: zodResolver(phoneNumberSchema),
    defaultValues: {
      phoneNumber: '',
    },
    mode: 'onTouched',
  });

  const onFormSubmit: SubmitHandler<FormData> = (data) => {
    onSubmit(data.phoneNumber);
  };

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <div className={styles.inputWrapper}>
        <Controller
          name="phoneNumber"
          control={control}
          render={({ field }) => (
            <SelectRCPhoneCountry {...field} className={styles.input} size={width > 1000 ? 'large' : 'medium'} />
          )}
        />
      </div>
      <div className={styles.buttons}>
        <Button className={styles.button} loading={isLoading} isDisabled={!isDirty || !isValid} type="submit">
          Update
        </Button>
        <Button className={styles.button} variants="subtle" size="mediumSubtle" onPress={onClose}>
          Cancel
        </Button>
      </div>
    </form>
  );
};
