import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { CodeCountdownTimer } from '@/components/common';
import { zodResolver } from '@hookform/resolvers/zod';
import { notEmptyDigitInputSecurity } from '@/utils/validation';
import { Button } from '../../../../buttons';
import { InputDigits } from '../../../../form/InputDigits';
import styles from './TwoFactorAuthVerification.module.css';

interface FormData {
  digits: string;
}

interface TwoFactorAuthVerificationProps {
  onSubmit: (data: FormData) => void;
  onSendEmail: () => void;
}

export const TwoFactorAuthVerification = ({ onSubmit, onSendEmail }: TwoFactorAuthVerificationProps) => {
  const [timeLeft, setTimeLeft] = useState(30);

  const {
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm<FormData>({
    resolver: zodResolver(notEmptyDigitInputSecurity),
    defaultValues: { digits: '' },
    mode: 'onTouched',
  });

  function sendCodeToEmail() {
    onSendEmail();
    setTimeLeft(30);
  }

  return (
    <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="digits"
        control={control}
        render={({ field }) => <InputDigits {...field} className={styles.inputDigits} />}
      />
      {timeLeft === 0 ? (
        <Button
          variants="subtle"
          size="mediumSubtle"
          icon="retry"
          className={styles.notification}
          onPress={() => setTimeLeft(30)}
        >
          Resend Code
        </Button>
      ) : (
        <CodeCountdownTimer className={styles.notification} timeLeft={timeLeft} setTimeLeft={setTimeLeft} />
      )}
      <div className={styles.buttons}>
        <Button className={styles.button} type="submit" isDisabled={!isValid}>
          Verify
        </Button>
        <Button className={styles.button} variants="subtle" size="mediumSubtle" onPress={sendCodeToEmail}>
          Send code by Email
        </Button>
      </div>
    </form>
  );
};
