import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  MfaType,
  useChangePasswordForAuthorizedUser,
  useCheckMfa,
  useGetUserQuery,
  useRecallVoiceOTP,
  useSendMfaSms,
  useSendVerificationCodeForAuthorisedUser,
  useVerifyVoiceOTP,
} from '@/api';
import { showNotification } from '@/utils/showNotification';
import { Verification, VerificationFormData } from '@/components/common';
import { BeforeChangeMessage } from '@/components/common/popups/BeforeChangeMessage';
import { EnterNewPassword, EnterNewPhone, TwoFactorAuthVerification } from './TwoFactorAuthSteps';
import styles from './TwoFactorAuth.module.css';

export interface TwoFactorAuthProps {
  type: 'Phone Number' | 'Password';
  onClose: () => void;
}

const comparePhoneNumbers = (currentNumber: string, newNumber: string) => {
  const cleanCurrentNumber = currentNumber.replace(/\D/g, '');
  const cleanNewNumber = newNumber.replace(/\D/g, '');
  return cleanCurrentNumber === cleanNewNumber;
};

export const TwoFactorAuth = ({ type, onClose }: TwoFactorAuthProps) => {
  const [step, setStep] = useState(1);
  const [verificationCode, setVerificationCode] = useState('');
  const [phone, setPhone] = useState('');
  const { refetch: refetchUser } = useGetUserQuery({ enabled: false });
  const { data: userData } = useGetUserQuery();

  const { data: mfa } = useCheckMfa();
  const userCurrentMobile = useMemo(() => userData?.mobile, [userData?.mobile]);

  const { mutate: recallVoiceOTPMutation } = useRecallVoiceOTP({
    onSuccess: (resp) => {
      if (!resp?.success && resp?.message && resp?.message.length > 0) {
        showNotification(resp?.message, 'error');
        onClose();
      }
      setStep(4);
    },
    onError: (error) => {
      showNotification(error.message);
    },
  });

  const { refetch: sendCodeToEmail } = useSendVerificationCodeForAuthorisedUser({
    enabled: false,
    onError() {
      showNotification('Something going wrong');
    },
  });

  const { mutate: sendMfaSms } = useSendMfaSms();

  useEffect(() => {
    if (step === 2 && mfa?.type === MfaType.SMS) {
      sendMfaSms();
    }
  }, [mfa?.type, type, step]);

  const onVerificationSubmit = useCallback((v: { digits: string }) => {
    setVerificationCode(v.digits);
    setStep(3);
  }, []);

  const { mutate: resetPassword, isLoading: isResetPasswordLoading } = useChangePasswordForAuthorizedUser({
    onSuccess() {
      onClose();
      showNotification('Password changed successfully', 'success');
    },
    onError(error) {
      if (error.response?.data.code === 'KYC_VERIFICATION_CODE_NOT_MATCHED') {
        showNotification('Wrong code');
      } else if (
        error.response?.data.code === 'AUTH_ERROR' &&
        error.response?.data.message === 'PasswordHistoryError: Password has previously been used'
      ) {
        showNotification('Password has previously been used');
      } else {
        showNotification(error.response?.data.message);
      }
    },
  });
  const { mutateAsync: verify, isLoading: isVerifyLoading } = useVerifyVoiceOTP();

  const onResetPasswordSubmit = useCallback(
    (v: { password: string; confirmPassword: string }) => {
      resetPassword({ newPassword: v.password, smsCode: verificationCode });
    },
    [resetPassword, verificationCode],
  );

  async function onUpdatePhoneSubmit(phoneNumber: string) {
    if (userCurrentMobile && comparePhoneNumbers(userCurrentMobile, phoneNumber)) {
      return showNotification('You have entered the same mobile number, please try again with another number', 'error');
    }

    setPhone(phoneNumber);
    try {
      await recallVoiceOTPMutation(phoneNumber || '');
    } catch (e) {
      showNotification();
    }
    return null;
  }

  async function onPhoneVerificationSubmit(data: VerificationFormData) {
    try {
      await verify({ verificationCode: data.digits }).then((resp) => {
        if (resp?.success) {
          showNotification('Phone number changed successfully', 'success');
        }
      });
      await refetchUser();
      onClose();
    } catch (e) {
      showNotification();
    }
  }

  const renderStep = (step: number, type: 'Phone Number' | 'Password') => {
    switch (step) {
      case 2:
        return <TwoFactorAuthVerification onSubmit={onVerificationSubmit} onSendEmail={sendCodeToEmail} />;
      case 3:
        return type === 'Phone Number' ? (
          <EnterNewPhone onClose={onClose} onSubmit={onUpdatePhoneSubmit} isLoading={isVerifyLoading} />
        ) : (
          <EnterNewPassword onClose={onClose} onSubmit={onResetPasswordSubmit} isLoading={isResetPasswordLoading} />
        );
      case 4:
        return (
          <Verification
            type="signUp"
            phone={phone}
            confirmValue="Phone"
            onFormSubmit={onPhoneVerificationSubmit}
            isLoading={isVerifyLoading}
          />
        );
      default:
        return <BeforeChangeMessage type={type} onClickNext={() => setStep(mfa?.type !== MfaType.NONE ? 2 : 3)} />;
    }
  };

  return (
    <>
      {step === 1 || step === 4 ? null : (
        <>
          <div className={styles.title}>{step === 2 ? 'Two-factor authentication' : `Update ${type}`}</div>
          <div className={styles.description}>
            {step === 2
              ? `To update your ${type?.toLowerCase()} \n you should pass two-factor authentication.`
              : `Enter new ${type?.toLowerCase()}`}
          </div>
        </>
      )}

      {renderStep(step, type)}
    </>
  );
};
