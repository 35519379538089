import { Icon } from '@/components/common';
import { Button } from '../../buttons';
import styles from './BeforeChangeMessage.module.css';

interface AgeWarningProps {
  onClickNext: () => void;
  type: 'Phone Number' | 'Password';
}

export const BeforeChangeMessage = ({ onClickNext, type }: AgeWarningProps) => {
  return (
    <div className={styles.container}>
      <Icon name="infoCircled" className={styles.icon} size={40} />
      <h3 className={styles.title}>Attention!</h3>

      <p className={styles.description}>
        {type === 'Phone Number'
          ? 'To change your phone number, please obtain a verification code first. Without the code, the change cannot be completed. Are you sure you want to proceed?'
          : 'To change your password, please obtain a verification code on your registered email address. Without the code, the password change cannot be completed. Are you sure you want to proceed?'}
      </p>

      <Button className={styles.button} onPress={onClickNext}>
        Proceed
      </Button>
    </div>
  );
};
