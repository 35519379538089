import Image from 'next/image';
import { useEffect, useMemo, useState } from 'react';
import { useGetUserQuery } from '@/api';
import { OnfidoStatus } from '@/api/common.types';
import { Button } from '../../buttons';
import styles from './VerifyYourIdentity.module.css';
import { Onfido } from '../../Onfido';
import { Spinner } from '../../Spinner';

interface VerifyYourIdentityProps {
  onCompleteOnfido: () => void;
  onAddressErrorOnfido?: () => void;
  isAttentionStepSkipped?: boolean;
}

export const VerifyYourIdentity = ({
  onCompleteOnfido,
  onAddressErrorOnfido,
  isAttentionStepSkipped = false,
}: VerifyYourIdentityProps) => {
  const [isStarted, setIsStarted] = useState(false);
  const [isInited, setIsInited] = useState(false);
  const { data: userData } = useGetUserQuery();

  const isInitialVerification = useMemo(() => {
    return userData?.onfidoStatus === OnfidoStatus.EMPTY;
  }, [userData?.onfidoStatus]);

  function start() {
    setIsStarted(true);
  }

  function onInit() {
    setIsInited(true);
  }

  useEffect(() => {
    if (isAttentionStepSkipped) {
      setIsStarted(true);
    }
  }, [isAttentionStepSkipped]);

  return (
    <div className={styles.container}>
      {isStarted && (
        <Onfido
          onInit={onInit}
          onComplete={onCompleteOnfido}
          onAddressError={onAddressErrorOnfido}
          smsNumber={userData?.mobile}
        />
      )}

      {isAttentionStepSkipped && !isInited && <Spinner />}

      {!isAttentionStepSkipped &&
        (isInited ? null : (
          <div className={styles.wrapper}>
            <Image src="/assets/verifyIdentity.svg" width={100} height={100} alt="Verification" />
            <h3 className={styles.title}>
              {isInitialVerification ? 'Verify Your Identity' : 'Re-verify Your Identity'}
            </h3>
            <p className={styles.description}>
              {isInitialVerification ? (
                <>
                  RelayPay is registered with AUSTRAC and as part of the AML & CTF guidelines we are required to perform
                  an ID verification to our users. Please visit our{' '}
                  <a
                    className={styles.link}
                    href="https://www.relaypay.io/terms-and-conditions"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms of Use
                  </a>{' '}
                  and{' '}
                  <a
                    className={styles.link}
                    href="https://www.relaypay.io/privacy-policy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </a>{' '}
                  for more information.
                </>
              ) : (
                `In light of recent increases in financial services related scams, we require you to complete biometric
            verifications before your transaction.`
              )}
            </p>
            <Button size="large" className={styles.button} loading={isStarted && !isInited} onPress={start}>
              Start Verification
            </Button>
          </div>
        ))}
    </div>
  );
};
