import { useQuery, UseQueryResult, UseQueryOptions } from 'react-query';
import { axiosClient, url } from '@/api';
import { AxiosError } from 'axios';
import { IList, SearchPaginationFilterParams } from '../common.types';

export enum ReferralStatus {
  SIGNED_UP = 'SIGNED_UP',
  TRADED = 'TRADED',
}
export interface IUserReferralDetail {
  earnedFromEmail?: string;
  currentRate?: number;
  signedUpDateAndTime?: string;
  referralStatus?: ReferralStatus;
}

export function useGetCodes<TSelectData = { code: string; name: string }, TError = AxiosError>(
  options?: Omit<UseQueryOptions<void, TError, TSelectData>, 'queryKey' | 'queryFn'> | undefined,
): UseQueryResult<TSelectData, TError> {
  return useQuery<void, TError, TSelectData>(
    ['GetCodes'],
    () => axiosClient.post(url.referralController.getCodes),
    options,
  );
}

export function useSendReferralLinkToEmail<TSelectData = void, TError = AxiosError>(
  options: [{ email: string }, Omit<UseQueryOptions<void, TError, TSelectData>, 'queryKey' | 'queryFn'> | undefined],
): UseQueryResult<TSelectData, TError> {
  return useQuery<void, TError, TSelectData>(
    ['SendReferralLinkToEmail'],
    () => axiosClient.get(url.referralController.sendToEmail(options[0].email)),
    options?.[1],
  );
}

export function useGetList<TSelectData = IList<IUserReferralDetail>, TError = AxiosError>(
  options: [
    SearchPaginationFilterParams,
    Omit<UseQueryOptions<void, TError, TSelectData>, 'queryKey' | 'queryFn'> | undefined,
  ],
): UseQueryResult<TSelectData, TError> {
  const queryKeys = options[0];
  return useQuery<void, TError, TSelectData>(
    ['GetList', ...Object.values(queryKeys)],
    () =>
      axiosClient.get(
        url.referralController.getList({
          page: options[0]?.page ?? 0,
          size: options[0]?.size ?? 8,
          sortField: options[0]?.sortField ?? 'created',
          isDesc: options[0]?.isDesc ?? true,
        }),
      ),
    options[1],
  );
}

export function useGetTotal<
  TSelectData = {
    totalEarningsAmount: number;
    availableToCashOut: number;
  },
  TError = AxiosError,
>(
  options?: Omit<UseQueryOptions<void, TError, TSelectData>, 'queryKey' | 'queryFn'> | undefined,
): UseQueryResult<TSelectData, TError> {
  return useQuery<void, TError, TSelectData>(
    ['GetTotal'],
    () => axiosClient.get(url.referralController.getTotal),
    options,
  );
}
