import clsx from 'clsx';
import { ReactNode } from 'react';
import { useWindowSize } from 'usehooks-ts';
import Image from 'next/image';
import { useSellCrypto } from '@/store/sellCrypto';
import { shallow } from 'zustand/shallow';
import styles from './ReadyToTransfer.module.css';

export interface PopupDataProps {
  title?: string;
  description?: ReactNode;
  children: ReactNode;
}

const readyToTransferData = (activeCard: number | null): PopupDataProps[] => {
  const endIconName = (idx: number) => (activeCard === idx ? '-white' : '');
  return [
    {
      title: 'Send manually from any exchange or wallet',
      children: (
        <>
          <Image
            width={272}
            className={styles.cardImage}
            height={73}
            src={`/assets/exchange-wallet${endIconName(0)}.svg`}
            alt=""
            priority
            loading="eager"
          />
        </>
      ),
    },
    {
      title: 'Connect & Send from a Web3 wallet',
      children: (
        <>
          <Image
            width={289}
            height={86}
            className={styles.cardImage}
            src={`/assets/other-wallets${endIconName(1)}.svg`}
            alt=""
            priority
            loading="eager"
          />
        </>
      ),
    },
  ];
};

interface ReadyToTransferProps {
  activeCard: number | null;
  selectCard: (idx: number) => void;
}
export const ReadyToTransfer = ({ activeCard, selectCard }: ReadyToTransferProps) => {
  const { width } = useWindowSize();

  const sellState = useSellCrypto((state) => state, shallow);

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>Ready to transfer?</h3>
      <p className={styles.description}>You can use any crypto wallet to transfer funds to RelayPay</p>
      <div className={styles.walletListWrapper}>
        <ul className={styles.walletList}>
          {readyToTransferData(activeCard).map((item, idx) => {
            return (
              <li className={styles.walletListItem} key={item.title} onClick={() => selectCard(idx)}>
                <div className={clsx(styles.card, { [styles.cardActive]: activeCard === idx })}>{item.children}</div>
                <h4 className={styles.walletListTitle}>{item.title}</h4>
              </li>
            );
          })}
        </ul>
      </div>
      {width < 1000 && (
        <>
          <div className={styles.gradient} />
          <div className={clsx(styles.gradient, styles.gradientTop)} />
        </>
      )}
    </div>
  );
};
