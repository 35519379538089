import React, { useEffect, useMemo } from 'react';
import { SdkTokenPayload, useCheckAddress, useCheckStatus, useGetSdkToken } from '@/api/queryHooks/useOnfidoController';
import { OnfidoStatus } from '@/api/common.types';
import { LocaleConfig, PublicStepConfig, PublicStepTypes, SupportedLanguages } from 'onfido-sdk-ui';
import { showNotification } from '@/utils/showNotification';

interface OnfidoProps {
  onInit: () => void;
  onComplete: () => void;
  onAddressError?: () => void;
  smsNumber?: string;
}
interface VerificationConfig {
  steps: (PublicStepTypes | PublicStepConfig)[];
  languageConfig: any;
}
// https://documentation.onfido.com/sdk/web
export const Onfido: React.FC<OnfidoProps> = ({ onInit, onComplete, onAddressError, smsNumber }) => {
  const { mutateAsync: getIsAddressValid } = useCheckAddress();
  const { mutateAsync: getSdkToken } = useGetSdkToken();
  const { data } = useCheckStatus();
  const isBiometric = data?.status === OnfidoStatus.BIOMETRIC_REQUIRED;

  const verificationConfig: VerificationConfig | undefined = useMemo(() => {
    if (isBiometric) {
      return {
        steps: [
          'welcome',
          {
            type: 'face',
            options: {
              requestedVariant: 'motion',
              uploadFallback: false,
            },
          },
          'complete',
        ],
        languageConfig: {
          locale: 'en',
          phrases: {
            welcome: {
              title: 'Biometric Verification',
              subtitle:
                'To keep your account and funds secure, we’ll reverify your biometrics with our existing records. This will take few seconds.',
              list_header_webcam: '',
              list_item_selfie: '',
              next_button: 'Start',
            },
            avc_intro: {
              title: 'Biometric Recording',
              subtitle:
                'Please prepare your camera and light for a biometric recording. On next step there will be a short recording to rematch your existing biometrics.',
            },
          },
        },
      };
    }

    return {
      steps: [
        {
          type: 'document',
          options: {
            documentTypes: {
              driving_licence: true,
              passport: true,
            },
          },
        },
        {
          type: 'face',
          options: {
            requestedVariant: 'motion',
            uploadFallback: false,
          },
        },
        'complete',
      ],
      languageConfig: {},
    };
  }, [isBiometric]);

  async function sdkInit() {
    const { addressValid } = await getIsAddressValid();

    if (!addressValid) {
      return onAddressError?.();
    }

    let sdkToken = { token: '' };
    try {
      sdkToken = await getSdkToken({
        verificationType: isBiometric ? 'BIOMETRIC' : 'REGISTRATION',
      });
    } catch (e: any) {
      if (e.response.data.status === 'CONFLICT') {
        return onAddressError?.();
      }
    }

    // eslint-disable-next-line import/extensions
    const sdk = await import('onfido-sdk-ui');
    if (!sdk) {
      throw new Error('OnfidoSDK import error');
    }
    sdk.init({
      token: sdkToken.token,
      containerId: 'onfidoContainerId',
      onComplete,
      userDetails: { smsNumber },
      steps: verificationConfig?.steps,
      language: verificationConfig?.languageConfig,
    });
    onInit();
    return null;
  }

  useEffect(() => {
    sdkInit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div id="onfidoContainerId"></div>;
};
