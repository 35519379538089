import clsx from 'clsx';
import Image from 'next/image';
import Lottie from 'react-lottie';
import Link from 'next/link';
import { useWindowSize } from 'usehooks-ts';
import completed from '../../../../public/animations/payment-completed.json';
import styles from './HowToUseIt.module.css';
import { PopupDataProps } from '../ReadyToTransfer';

const FULL_LIST_GIFTCARD_URL = 'https://giftagiftcards.com.au/shop';

export const HowToUseIt = () => {
  const { width } = useWindowSize();
  const howToUseItData: PopupDataProps[] = [
    {
      title: '1. Choose Gift Card',
      description: 'First of all you need to pick your gift card or enter amount',
      children: (
        <Image
          src="/assets/GiftCards.svg"
          width={width > 1000 ? 288 : 308}
          height={188}
          alt="Gift Cards"
          priority
          loading="eager"
        />
      ),
    },
    {
      title: '2. Make a Payment',
      description:
        'Once, the payment is done we`ll proceed with the order further and the digital gift card code should arrive within a few moments.',
      children: (
        <>
          <Lottie
            ariaRole=""
            isClickToPauseDisabled
            options={{
              loop: true,
              autoplay: true,
              animationData: completed,
            }}
            width={width > 1000 ? 64 : 48}
            height={width > 1000 ? 64 : 48}
            isPaused={false}
          />
          <h4 className={styles.giftCardSubtitle}>Transfer Completed</h4>
          <p className={styles.giftCardDescription}>Thank you for using RelayPay.</p>
        </>
      ),
    },
    {
      title: '3. Use it in-store or online',
      description: (
        <>
          Once you get a gift card, it can be used in 200+ stores worldwide.
          <br /> See{' '}
          <Link className={styles.link} href={FULL_LIST_GIFTCARD_URL} target="_blank">
            full list
          </Link>{' '}
          of available stores.
        </>
      ),
      children: (
        <>
          <div className={clsx(styles.row, styles.imageRow)}>
            <Image
              width={148}
              height={18}
              src="/assets/TheIconic.svg"
              alt="The Iconic"
              className={styles.theIconicImage}
              priority
              loading="eager"
            />
            <Image width={58} height={20} src="/assets/Uber.svg" alt="Uber" priority loading="eager" />
          </div>
          <div className={clsx(styles.row, styles.imageRow)}>
            <Image
              width={80}
              height={14}
              src="/assets/Amazon.svg"
              alt="Amazon"
              className={styles.amazonImage}
              priority
              loading="eager"
            />
            <Image width={77} height={24} src="/assets/Airbnb.svg" alt="Airbnb" priority loading="eager" />
          </div>
        </>
      ),
    },
  ];

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>How to use it?</h3>
      <p className={styles.description}>
        Quick guide on how to use RelayPay gift cards. Ready to use online or in-store. Buy gift cards with Bitcoin,
        Ether, Tether, and more.
      </p>
      <div className={styles.giftListWrapper}>
        <ul className={styles.giftList}>
          {howToUseItData.map((item) => {
            return (
              <li className={styles.giftListItem} key={item.title}>
                <div className={styles.giftCard}>{item.children}</div>
                <h4 className={styles.giftCardTitle}>{item.title}</h4>
                <p className={styles.giftCardDescription}>{item.description}</p>
              </li>
            );
          })}
        </ul>
      </div>
      {width < 1000 && (
        <>
          <div className={styles.gradient} />
          <div className={clsx(styles.gradient, styles.gradientTop)} />
        </>
      )}
    </div>
  );
};
