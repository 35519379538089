import { Button } from '../../buttons';
import styles from './TakeSelfie.module.css';

interface TakeSelfieProps {
  onClick: () => void;
  onBackClick: () => void;
}

export const TakeSelfie = ({ onClick, onBackClick }: TakeSelfieProps) => {
  return (
    <div className={styles.container}>
      <Button
        variants="subtle"
        size="mediumSubtle"
        icon="chevronLeft"
        className={styles.buttonBack}
        iconClassName={styles.buttonBackIcon}
        onPress={() => onBackClick()}
      />
      <h3 className={styles.title}>Take a Selfie</h3>
      <p className={styles.description}>
        Please make a selfie or upload photo so we can compare it with your
        document.
      </p>
      <div className={styles.inner}>
        <h4 className={styles.subtitle}>Follow the guideline below:</h4>
        <ul className={styles.list}>
          <li className={styles.listItem}>
            <div className={styles.listItemDot} />
            Face forward and make sure your eyes are clearly visible;
          </li>
          <li className={styles.listItem}>
            <div className={styles.listItemDot} />
            Remove your glasses if necessary.
          </li>
        </ul>
      </div>
      <Button size="large" className={styles.button} onPress={() => onClick()}>
        Take a Selfie
      </Button>
      <Button
        variants="subtle"
        size="mediumSubtle"
        icon="downloadApp"
        iconClassName={styles.icon}
        onPress={undefined}
      >
        Open on mobile
      </Button>
    </div>
  );
};
