import Image from 'next/image';
import { useCopyToClipboard, useWindowSize } from 'usehooks-ts';
import { useEffect, useState } from 'react';
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
} from 'react-share';

import { toast } from 'react-toastify';
import { useGetCodes, useSendReferralLinkToEmail } from '@/api/queryHooks/useReferralController';
import { removeTrailingSlash, validateEmail } from '@/utils/functions';
import { Input } from '../../form';
import styles from './InviteFriends.module.css';
import { Button } from '../../buttons';

export const InviteFriends = () => {
  const { width } = useWindowSize();
  const [_, copy] = useCopyToClipboard();
  const [email, setEmail] = useState('');
  const [inviteLink, setInviteLink] = useState('');
  const { isFetching, isSuccess, refetch } = useSendReferralLinkToEmail([{ email }, { enabled: false, retry: false }]);
  const { data: { code } = {} } = useGetCodes();

  function sendInvitation() {
    if (!email) {
      return toast.error('Email is required', {
        position: toast.POSITION.TOP_CENTER,
      });
    }

    const isValidEmail = validateEmail(email);
    if (!isValidEmail) {
      return toast.error('Wrong email format', {
        position: toast.POSITION.TOP_CENTER,
      });
    }

    refetch();

    return null;
  }

  function copyInviteLink() {
    copy(inviteLink);
  }

  useEffect(() => {
    if (code) {
      setInviteLink(`${removeTrailingSlash(process.env.NEXT_PUBLIC_WEB_APP_URL ?? '')}/r?code=${code}`);
    }
  }, [code]);

  useEffect(() => {
    if (isSuccess) {
      toast.success(`Invitation link sent to ${email}!`, {
        position: toast.POSITION.TOP_CENTER,
      });
      setEmail('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  return (
    <div className={styles.container}>
      <Image
        width={width > 1000 ? 67 : 55}
        height={width > 1000 ? 87 : 72}
        alt="invite"
        src="/assets/InviteFriends.svg"
        className={styles.inviteImage}
        priority
        loading="eager"
      />
      <h3 className={styles.title}>Invite & Earn</h3>
      <p className={styles.description}>
        Invite your friends to RelayPay and you&apos;ll earn 0.10% of their total transaction volume. All they have to
        do is click the link you send, create an account, and complete a transaction.
      </p>
      <div className={styles.innerContainer}>
        <Input
          copyButton
          label="Your Referral Link"
          defaultValue={inviteLink}
          isReadOnly
          className={styles.inputWrapper}
          inputClassName={styles.input}
          size={width > 1000 ? 'large' : 'medium'}
          onButtonPress={copyInviteLink}
        />
        <Input
          buttonLoading={isFetching}
          primaryButtonText={width > 1000 ? 'Send invites' : ''}
          label="Send by Email"
          placeholder="Enter email address"
          size={width > 1000 ? 'large' : 'medium'}
          value={email}
          onChange={setEmail}
          onButtonPress={sendInvitation}
        />
        <div className={styles.shareButtons}>
          <TwitterShareButton className={styles.shareButton} url={inviteLink}>
            <TwitterIcon size={36} round />
          </TwitterShareButton>
          <FacebookShareButton className={styles.shareButton} url={inviteLink}>
            <FacebookIcon size={36} round />
          </FacebookShareButton>
          <LinkedinShareButton className={styles.shareButton} url={inviteLink}>
            <LinkedinIcon size={36} round />
          </LinkedinShareButton>
        </div>
        {width < 1000 && (
          <Button className={styles.sendInvitesButton} fluid loading={isFetching} onPress={sendInvitation}>
            Send invites
          </Button>
        )}
      </div>
    </div>
  );
};
