export * from './ScamWarning';
export * from './VerifyYourIdentity';
export * from './TakeSelfie';
export * from './CheckSelfie';
export * from './VerificationPending';
export * from './VerificationSuccessful';
export * from './VerificationFailed';
export * from './EnterTheCode';
export * from './HowToUseIt';
export * from './ReadyToTransfer';
export * from './InviteFriends';
export * from './TwoFactorAuth';
export * from './InvoiceToHostedPage';
export * from './UpdateLocation';
export * from './HighDemand';
export * from './TermsAndPrivacy';
