import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import { notEmptyDigitInputSecurity } from '@/utils/validation';
import { VerificationFormData } from '@/components/common';
import clsx from 'clsx';
import { useState } from 'react';
import { Button } from '../../buttons';
import { InputDigits } from '../../form/InputDigits';
import { Icon } from '../../Icon';
import styles from './EnterTheCode.module.css';

interface EnterTheCodeProps {
  phoneNumber?: string;
  className?: string;
  onRetry?: () => void;
  onFormSubmit?: (values: VerificationFormData) => void;
  isPopup?: boolean;
  isLoading?: boolean;
}

export interface EnterTheCodeFormData {
  digits: string;
}

export const EnterTheCode = ({
  phoneNumber,
  onRetry,
  className,
  onFormSubmit,
  isPopup = true,
  isLoading,
}: EnterTheCodeProps) => {
  const {
    handleSubmit,
    control,
    formState: { isDirty, isValid },
  } = useForm<EnterTheCodeFormData>({
    resolver: zodResolver(notEmptyDigitInputSecurity),
    defaultValues: { digits: '' },
    mode: 'onTouched',
  });

  const [isReCalling, setIsReCalling] = useState(false);

  function onSubmit(data: EnterTheCodeFormData) {
    onFormSubmit?.(data);
  }

  function onRecall() {
    if (isReCalling) return;
    onRetry?.();
    setIsReCalling(true);
    setTimeout(() => {
      setIsReCalling(false);
    }, 15000);
  }

  return (
    <form className={clsx(styles.container, className)} onSubmit={handleSubmit(onSubmit)}>
      {isPopup && (
        <Button
          variants="subtle"
          size="mediumSubtle"
          icon="chevronLeft"
          className={styles.buttonBack}
          iconClassName={styles.buttonBackIcon}
        />
      )}
      <h3 className={styles.title}>Enter the code</h3>
      <p className={styles.description}>
        We’re calling on <span className={styles.phoneNumber}>{phoneNumber}</span> to tell you a 6-digit code
        confirmation. Please, enter this code to verify your phone number.
      </p>
      <div className={styles.circle}>
        <div className={styles.innerCircle} />
        <Icon name="phone" size={24} className={styles.phoneIcon} />
      </div>
      <Controller
        name="digits"
        control={control}
        render={({ field }) => <InputDigits {...field} className={styles.input} />}
      />
      <Button
        type="submit"
        size="large"
        className={styles.button}
        isDisabled={!isDirty || !isValid}
        loading={isLoading}
      >
        Continue
      </Button>
      <Button
        variants="subtle"
        size="mediumSubtle"
        icon={isReCalling ? 'check' : 'retry'}
        iconClassName={styles.icon}
        onPress={onRecall}
      >
        {isReCalling ? 'We are calling you...' : 'Re-call me'}
      </Button>
    </form>
  );
};
