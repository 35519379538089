import { SUPPORT_EMAIL } from '@/constants/common';
import clsx from 'clsx';
import { Button } from '../../buttons';
import { Icon } from '../../Icon';
import styles from './VerificationFailed.module.css';

interface VerificationFailedProps {
  status: 'general' | 'multipleVerification';
}

export const VerificationFailed = ({ status }: VerificationFailedProps) => {
  return (
    <div className={styles.container}>
      <Icon name={status === 'general' ? 'infoCircled' : 'verification'} className={styles.icon} size={64} />
      <h3 className={styles.title}>
        {status === 'general' ? 'Oops.. something goes wrong' : 'Multiple verification attempts'}
      </h3>
      {status === 'general' ? (
        <>
          <p className={clsx(styles.description, styles.generalDescription)}>
            We’ve noticed some issues during verification. To solve them, please, reach out to support:
          </p>
          <a className={styles.link} href={SUPPORT_EMAIL}>
            support@relaypay.io
          </a>
        </>
      ) : (
        <p className={clsx(styles.description, styles.multipleDescription)}>
          Your verification attempts require a closer look. Please reach out to our customer support at{' '}
          <a className={styles.link} href={SUPPORT_EMAIL}>
            support@relaypay.io
          </a>{' '}
          to book in a time for manual verification.
        </p>
      )}
      <Button href={SUPPORT_EMAIL} className={styles.button}>
        Write a message
      </Button>
    </div>
  );
};
