import Image from 'next/image';
import { Button } from '../../buttons';
import styles from './CheckSelfie.module.css';

interface CheckSelfieProps {
  onClick: () => void;
  onBackClick: () => void;
}

export const CheckSelfie = ({ onClick, onBackClick }: CheckSelfieProps) => {
  return (
    <div className={styles.container}>
      <Button
        variants="subtle"
        size="mediumSubtle"
        icon="chevronLeft"
        className={styles.buttonBack}
        iconClassName={styles.buttonBackIcon}
        onPress={() => onBackClick()}
      />
      <h3 className={styles.title}>Check Selfie</h3>
      <p className={styles.description}>
        Make sure your selfie clearly shows your face.
      </p>
      <div className={styles.photoWrapper}>
        <Image
          fill
          alt="selfie"
          src="/assets/verifyIdentity.svg"
          className={styles.photo}
        />
      </div>
      <Button size="large" className={styles.button} onPress={() => onClick()}>
        Confirm
      </Button>
      <Button
        variants="subtle"
        size="mediumSubtle"
        icon="retry"
        iconClassName={styles.icon}
        onPress={undefined}
      >
        Redo
      </Button>
    </div>
  );
};
