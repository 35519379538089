import Lottie from 'react-lottie';
import completed from '../../../../public/animations/payment-completed.json';
import { Button } from '../../buttons';
import styles from './VerificationSuccessful.module.css';

interface VerificationSuccessfulProps {
  onClick: () => void;
}

export const VerificationSuccessful = ({
  onClick,
}: VerificationSuccessfulProps) => {
  return (
    <div className={styles.container}>
      <Lottie
        isClickToPauseDisabled
        options={{
          loop: true,
          autoplay: true,
          animationData: completed,
        }}
        width={64}
        height={64}
        isPaused={false}
        ariaRole=""
      />
      <h3 className={styles.title}>Verification Successful</h3>
      <p className={styles.description}> Let’s finish the purchase!</p>
      <Button onPress={() => onClick()} className={styles.button}>
        Go to checkout
      </Button>
    </div>
  );
};
